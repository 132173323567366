import { render, staticRenderFns } from "./DelayNotify.vue?vue&type=template&id=ba610406&scoped=true"
import script from "./DelayNotify.vue?vue&type=script&lang=js"
export * from "./DelayNotify.vue?vue&type=script&lang=js"
import style0 from "./DelayNotify.vue?vue&type=style&index=0&id=ba610406&prod&scoped=true&lang=css"
import style1 from "./DelayNotify.vue?vue&type=style&index=1&id=ba610406&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba610406",
  null
  
)

export default component.exports